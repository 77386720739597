summary {
  list-style-type: none;
  background-color: #ea9c34;
}

summary::webkit-details-marker {
  display: none;
}

.accordion-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 20px;
}

details[open] summary ~ * {
  animation: toggle 0.5s ease-in-out;
}
@keyframes toggle {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.accordion-item summary h6 {
  color: #14212b;
  padding: 0.7rem;
}
.accordion-item p {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8rem;
  /* color: #ea9c34; */
  padding: 0.7rem;
  background-color: white;
}
