.signup {
  color: white;
  font-weight: 600;
  margin-right: 2rem;

  &:hover {
    color: $theme-primary-color;
  }
}

.footer-li {
  color: white;

  & li a:hover {
    color: $theme-primary-color;
  }

  & li a {
    color: white;
  }
}

.newsletter-widget {
  & p {
    margin-bottom: 1rem;
  }
  & a {
    color: white;
    margin-right: 2rem;

    & i {
      padding-right: 1rem;
      color: $theme-primary-color;
    }
    &:hover {
      color: $theme-primary-color;
    }
  }
}

.about-widget {
  padding-left: 10px;

  & .about-widget-content {
    color: white;
  }
}

.partners-section-s2 {
  & p {
    text-align: center;
    margin-bottom: 3rem;
  }
  & h3 {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.call-to-action {
  & div h2 {
    cursor: default;
    color: white;
    -webkit-transition: color 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out;
    -ms-transition: color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out;
  }
}
.call-to-action-left {
  background-color: $red;
  padding: 5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  &::before {
    position: absolute;
    left: -120px;
    bottom: 0px;
    width: 538px;
    height: 190px;
    background-image: url(../images/shape-9.png);
    content: "";
  }
  &:hover {
    & div h2 {
      color: $dark-gray2;
    }
  }
}
.call-to-action-right {
  background-color: $theme-primary-color;
  padding: 5rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  &::before {
    position: absolute;
    right: -120px;
    bottom: 0px;
    width: 538px;
    height: 190px;
    background-image: url(../images/shape-9.png);
    content: "";
  }
  &:hover {
    & div h2 {
      color: $dark-gray2;
    }
  }
}

.call-to-action-about {
  background-color: $theme-primary-color;
  padding: 5rem;
  &.home {
    position: relative;
    background-image: url(../images/custom/cta-bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
    & div.container > div.cta-wrapper {
      & div.cta-text {
        & div {
          color: $theme-primary-color !important;
          font-weight: 800;
        }
      }
    }
  }
  &::before {
    top: 0;
    left: 0;
    position: absolute;
    content: "";
    background-color: $dark-gray2;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    z-index: -1;
  }
  & div.container > div.cta-wrapper {
    display: flex;
    justify-content: space-between;

    & div.cta-text {
      & h3 {
        color: white !important;
      }
      & div {
        color: black !important;
        font-weight: 800;
      }
    }

    & div .theme-btn {
      background: $dark-gray2;
      &::after {
        display: none;
      }
      &:hover,
      :active,
      :focus {
        background: darken($dark-gray2, 5%);
      }
    }
  }
  &.home {
    & div.container > div.cta-wrapper {
      & div .theme-btn {
        background: $theme-primary-color;
        &::after {
          display: none;
        }
        &:hover,
        :active,
        :focus {
          background: darken($theme-primary-color, 5%);
        }
      }
    }
  }
  @media (max-width: 768px) {
    .cta-wrapper {
      flex-direction: column;
      gap: 2rem;
    }
  }
}

.core-values {
  padding-top: 1rem;
  font-size: medium;
  font-weight: 500;
  display: flex;
  justify-content: space-around;

  & ul {
    width: 110px;
  }

  & ul li span {
    position: relative;
    text-transform: capitalize;
  }
  & ul li span::before {
    position: absolute;
    top: 50%;
    left: -30px;
    content: "\f102";
    font-family: "Flaticon";
    transform: translateY(-50%);
    color: $theme-primary-color;
  }
}

@media (max-width: 350px) {
  .core-values {
    flex-direction: column;
    align-items: center;
  }
}

.wpo-testimonial-section {
  background: #343a40;

  .wpo-section-title {
    h2 {
      color: white;
    }
  }
}

.partners-section-s2 {
  .partner-grids {
    & .slick-slider {
      .slick-slide {
        padding: 0 10px;
        & img {
          height: 60px;
        }
      }
      & .slick-prev,
      .slick-next {
        background-color: transparentize($theme-primary-color, 0.3);
        width: 45px;
        height: 45px;
        z-index: 10;
        @include rounded-border(50%);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        border: 2px solid $theme-primary-color;

        &:hover {
          background-color: $theme-primary-color;
        }
      }

      .slick-prev {
        left: -40px;

        @include media-query(767px) {
          display: none !important;
        }

        &:before {
          font-family: "themify";
          content: "\e629";
          opacity: 1;
        }
      }

      .slick-next {
        right: -40px;

        @include media-query(767px) {
          display: none !important;
        }

        &:before {
          font-family: "themify";
          content: "\e628";
          opacity: 1;
        }
      }

      &:hover {
        .slick-next {
          right: -30px;
          opacity: 1;
          visibility: visible;
        }

        .slick-prev {
          left: -30px;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.wpo-project-section-s2 {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(../images/pattern-16.png);
}
.wpo-service-section-s4 {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(../images/pattern-15.jpg);
}

.wpo-about-section-s4,
.wpo-about-section-s3 {
  .text-sub-wrap {
    & > .text-sub-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      & .icon {
        height: 60px;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.application_list{
  display: flex;
  @include media-query(1024px) {
    flex-direction:column;
    gap: 20px;
  }
  & > .image_div {
    height: 100%;
  }
}
