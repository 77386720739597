/******************************
	#page title
******************************/
.page-title {
  width: 100%;
  height: 480px;
  text-align: center;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .page-inner-wrap {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }

  @include media-query(991px) {
    height: 400px;
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: $dark-gray2;
    opacity: 0.5;
    z-index: -1;
  }

  .container {
    height: 100%;
    display: table;

    > .row {
      vertical-align: middle;
      display: table-cell;
    }
  }

  h2,
  ol {
    color: $white;
  }

  h2 {
    font-size: 60px;
    font-size: calc-rem-value(60);
    font-weight: bold;
    margin: -0.12em 0 0.3em;
    text-transform: capitalize;

    @include media-query(991px) {
      font-size: 35px;
      font-size: calc-rem-value(35);
    }

    @include media-query(767px) {
      font-size: 30px;
      font-size: calc-rem-value(30);
    }
    @include media-query(575px) {
      font-size: 22px;
      font-size: calc-rem-value(22);
    }

    @include widther(992px) {
      margin-top: 145px;
    }

    @media (max-width: 991px) {
      margin-top: 140px;
    }
    @media (max-width: 590px) {
      margin-top: 180px;
    }
  }

  .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin-bottom: 0;
    justify-content: center;

    @include media-query(767px) {
      text-align: center;
    }
  }

  .breadcrumb li {
    font-size: 16px;
    font-size: calc-rem-value(16);
    color: $white;
    margin-right: 5px;

    @include media-query(767px) {
      font-size: 14px;
      font-size: calc-rem-value(14);
    }
  }

  .breadcrumb li a {
    color: $white;
  }

  .breadcrumb li a:hover {
    color: darken($theme-primary-color, 10%);
  }

  .breadcrumb > li + li {
    position: relative;
    padding-left: 20px;
    margin-left: 6px;

    &:before {
      margin-right: 5px;
      width: 8px;
      height: 4px;
      content: "";
      background: $white;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 2px;
    }
  }

  .page-title-shape-1 {
    position: absolute;
    right: -70px;
    top: -70px;
    width: 150px;
    height: 150px;
    background: $theme-primary-color;
    border-radius: 50%;
    z-index: -1;
    @media (max-width: 575px) {
      display: none;
    }
  }

  .page-title-shape-2 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    @media (max-width: 767px) {
      left: -100px;
      top: -100px;
    }
    @media (max-width: 575px) {
      display: none;
    }
  }
  .page-title-shape-3 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;

    @media (max-width: 767px) {
      right: -100px;
      bottom: -30px;
    }
    @media (max-width: 575px) {
      display: none;
    }
  }
}
