/*--------------------------------------------------------------
21. wpo-contact-pg-section
--------------------------------------------------------------*/
.wpo-contact-pg-section {
  // padding-top: 50px;
  padding: 0px;
  position: relative;
  overflow: hidden;
  margin-bottom: -6px;
  .contact-us-bg {
    &::before {
      position: absolute;
      left: 0;
      height: 100%;
      width: 100%;
      background-image: url(../../images/custom/book-demo-bg.jpg);
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.7;
      content: "";
      z-index: -1;
    }
  }
  .wpo-contact-title {
    max-width: 440px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }

    h2 {
      font-size: 35px;
      font-size: calc-rem-value(35);
      font-weight: 700;
      margin-bottom: 20px;

      @media (max-width: 575px) {
        font-size: 25px;
        font-size: calc-rem-value(25);
      }
    }
  }

  form input,
  form select,
  form textarea {
    background: transparent;
    width: 100%;
    height: 50px;
    border: 1px solid transparent;
    border-radius: 0px;
    box-shadow: none !important;
    padding-left: 25px;
    border: 1px solid #ebebeb;

    @include media-query(991px) {
      height: 45px;
    }

    // &:focus {
    // 	border-color: $theme-primary-color;
    // 	background: transparent;
    // }
  }

  form textarea {
    height: 180px;
    padding-top: 15px;
  }

  form {
    margin: 0 -15px;
    overflow: hidden;

    @include placeholder-style(#9d9c9c, 15px, normal);

    select {
      display: inline-block;
      color: #a9a9a9;
      cursor: pointer;
      opacity: 1;
      padding: 6px 25px;
      font-size: 15px;
      font-size: calc-rem-value(15);
      -webkit-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      -moz-appearance: none;
      background: transparent url(../../images/select-icon2.png) no-repeat
        calc(100% - 15px) center;
      position: relative;

      &:focus {
        background: transparent url(../../images/select-icon2.png) no-repeat
          calc(100% - 15px) center;
      }
    }

    .submit-area {
      text-align: center;
      width: 100%;
      margin-bottom: 10px;
      margin-left: 0;

      @include media-query(767px) {
        margin-bottom: 0;
      }

      .theme-btn {
        border-radius: 0px;
        font-family: $base-font;
        font-size: 16px;

        &:after {
          border-radius: 0px;
        }
      }
    }

    .form-field {
      margin-bottom: 30px;
      & .form-control {
        border-radius: 5px;
      }
    }
    .errorMessage {
      color: red;
      margin-top: 5px;
    }
  }

  form > div {
    margin: 0 15px 25px;

    @include media-query(600px) {
      float: none;
    }
  }

  .office-info {
    padding-top: 50px;
    padding-bottom: 100px;
    background-color: white;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }

    .col {
      .office-info-item {
        @media (max-width: 1200px) {
          margin-bottom: 30px;
        }
      }

      &:last-child {
        .office-info-item {
          @media (max-width: 1200px) {
            margin-bottom: 0px;
          }
        }
      }
    }

    .office-info-item {
      height: 20rem;
      border-radius: 10px;
      position: relative;
      z-index: 1;
      position: relative;
      overflow: hidden;
      padding: 40px;
      background-color: #ffffff;
      background-image: url(../../images/mission-vision.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transition: all 300ms linear;

      text-align: center;
      box-shadow: 0px 5px 15px 0px rgba(62, 65, 159, 0.1);
      padding: 40px;
      &::before {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #ffffff;
        content: "";
        transition: all 300ms linear;
        z-index: -1;
      }

      .shape-1 {
        position: absolute;
        left: -70px;
        bottom: -70px;
        width: 150px;
        height: 150px;
        background: #ea9c34;
        border-radius: 50%;
        z-index: -1;
      }

      &:hover {
        box-shadow: none;
        &:before {
          height: 0;
        }
        .office-info-text {
          h2 {
            color: $theme-primary-color;
          }
          p {
            color: $light;
          }
        }
      }
      .office-info-icon {
        width: 85px;
        height: 85px;
        background: $theme-primary-color;
        line-height: 85px;
        text-align: center;
        margin: 0 auto;
        @include rounded-border(50%);

        .icon {
          i {
            color: white;
          }
          .fi {
            &:before {
              font-size: 35px;
              line-height: unset;
            }
          }
        }
      }

      .office-info-text {
        h2 {
          font-size: 30px;
          font-weight: 500;
          margin: 20px 0;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .wpo-contact-form-area {
    padding: 50px;
    background: $white;
    box-shadow: 0px 1px 15px 0px rgba(62, 65, 159, 0.1);
    padding-bottom: 40px;
    margin-bottom: -25px;
    position: relative;
    z-index: 99;

    @media (max-width: 767px) {
      padding: 30px;
      padding-top: 50px;
    }
    @media (width < 1020px) {
      margin-bottom: -21.8px;
    }
  }
}

.wpo-contact-map-section {
  .wpo-contact-map {
    height: 550px;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
  }

  h2.hidden {
    display: none;
  }
}
