/*======================================
9. About Page
 =======================================*/

/* 9.1 wpo-mission-vission-section */

.wpo-mission-vission-section {
  padding: 70px 0;
  background-color: white;
  .wpo-mission-item,
  .wpo-vission-item {
    height: 20rem;
    // padding: 50px;
    // background: $dark-gray2;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    position: relative;
    overflow: hidden;
    padding: 40px 40px 30px;
    background-color: #ffffff;
    background-image: url(../../images/mission-vision.jpg);
    // @include background;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: all 300ms linear;
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: #ffffff;
      content: "";
      transition: all 300ms linear;
      z-index: -1;
    }

    &:hover {
      box-shadow: none;
      &:before {
        height: 0;
      }
      .wpo-mission-text,
      .wpo-vission-text {
        h2 {
          color: $theme-primary-color;
        }
        p {
          color: $light;
        }
      }
    }

    @media (max-width: 575px) {
      padding: 20px;
    }

    .shape-1 {
      position: absolute;
      left: -70px;
      bottom: -70px;
      width: 200px;
      height: 200px;
      background: $red;
      border-radius: 50%;
      z-index: -1;
    }

    .shape-2 {
      position: absolute;
      right: 0;
      top: 0;
    }

    .wpo-mission-text,
    .wpo-vission-text {
      & > div {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 20px;
        .icon {
          height: 70px;
          // padding: 5px;
          // background-color: #ffaa17;
          display: flex;
          justify-content: center;
          align-items: center;
          aspect-ratio: 1/1;
        }
      }
      h2 {
        z-index: 3;
        color: $dark-gray;
        font-size: 24px;
        transition: all 300ms linear;
      }

      p {
        transition: all 300ms linear;
        color: #616161;
        font-size: 15px;
        margin-bottom: 20px;
      }

      h5 {
        color: $white;
        font-size: 18px;
        line-height: 27px;
      }
    }
  }

  // .wpo-vission-item {
  //   background-color: $section-bg-color-s2;

  //   @media (max-width: 991px) {
  //     margin-top: 30px;
  //   }
  //   .wpo-vission-text {
  //     p {
  //       color: #616161;
  //       margin-bottom: 0;
  //     }

  //     h5 {
  //       color: $dark-gray2;
  //       margin-bottom: 20px;
  //     }
  //   }
  // }
}

/* 9.2 wpo-about-section-s4 */

.wpo-about-section-s4 {
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    left: -210px;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: url(../../images/pattern-7.png);
    background-repeat: no-repeat;
    background-position: center left;
    content: "";
  }

  @media (max-width: 991px) {
    padding-top: 0;
  }
  .wpo-about-inner {
    box-shadow: none;
    padding: 0;
    margin-top: 0;

    .wpo-about-text {
      padding-left: 0;
      @media (max-width: 991px) {
        padding-top: 0;
        margin-bottom: 40px;
      }
    }
  }

  .shape-ab {
    position: absolute;
    right: -150px;
    bottom: -180px;
    width: 330px;
    height: 330px;
    background: $theme-primary-color;
    border-radius: 40px;
    transform: rotate(20deg);
    z-index: 110;

    @media (max-width: 767px) {
      right: -50px;
      bottom: -80px;
      width: 200px;
      height: 200px;
    }
    @media (max-width: 575px) {
      right: -50px;
      bottom: -80px;
      width: 160px;
      height: 160px;
    }

    &:before {
      position: absolute;
      left: -30px;
      top: 30px;
      width: 100%;
      height: 100%;
      border: 1px solid $dark-gray2;
      content: "";
      border-radius: 40px;
    }
  }

  .wpo-about-wrap {
    .wpo-about-img {
      max-width: 100%;
    }
  }
}
