.loginWrapper {
  display: flex;
  min-height: 100vh;
  padding: 80px 0;
  background: #f9f9f9;

  .loginForm {
    width: 500px;
    max-width: 90%;
    margin: auto;
    background: #fff;
    padding: 70px 50px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);

    > h2 {
      text-align: center;
      margin-bottom: 20px;
    }

    > p {
      font-size: 13px;
      margin-bottom: 30px;
      text-align: center;
      font-weight: 500;
      color: #666666;
    }

    form {
      .inputOutline {
        label {
          font-size: 16px;
          background: #fff;
          padding: 0 10px 0 0;
        }

        input {
          font-size: 13px;
          letter-spacing: 0;
        }

        input::-webkit-input-placeholder {
          color: #888888;
          opacity: 1;
        }

        input::-moz-placeholder {
          color: #888888;
          opacity: 1;
        }

        input:-ms-input-placeholder {
          color: #888888;
          opacity: 1;
        }

        input:-moz-placeholder {
          color: #888888;
          opacity: 1;
        }
      }
    }

    .formAction {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;

      label {
        span {
          font-size: 14px;

          svg {
            width: 1.2em;
            height: 1.2em;
          }
        }
      }

      a {
        display: block;
        letter-spacing: 0;
        font-size: 13px;
        font-weight: 500;
        color: #246fea;
        cursor: pointer;
        transition: all 0.3s ease 0s;
      }
    }

    .formFooter {
      margin-top: 20px;
      display: flex;
    }

    .loginWithSocial {
      display: flex;
      justify-content: center;
      width: 320px;
      margin: 20px auto 0;

      button {
        min-width: unset;
        flex: 1 1;
        display: block;
        text-align: center;
        background: #3b5998;
        margin: 5px;
        color: #fff;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        font-size: 14px;
        padding: 4px 9px;

        &.facebook {
          background: #3b5998;
        }

        &.twitter {
          background: #55acee;
        }

        &.linkedin {
          background: #0077b5;
        }
      }
    }

    .noteHelp {
      text-align: center;
      margin-top: 20px;
      font-size: 13px;
      letter-spacing: 0;
      font-weight: 500;

      a {
        display: inline-block;
        color: #0077b5;
        margin-left: 10px;
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}

.login {
  background: #ec9b22 url(../../images/signupbg.gif) 0 0 no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginBox {
  background: #f8f9fb;
  padding: 20px 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  text-align: center;
}

.loginBox .logowrap {
  margin: 20px 0;
  width: 100%;
  display: inline-block;
}

.loginBox form label {
  display: block;
}

.loginBox form label span {
  display: block;
  padding-bottom: 5px;
}

.loginBox a img {
  width: 140px;
}

.login-text {
  font-size: 32px;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;

  &.success {
    color: #228f1f;
  }
}

.btn-signup {
  color: white;
  background: #3a8396;
  border-radius: 5px;
  border: 0;
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  text-align: center;
  min-width: 120px;
  margin: 0 5px;
  cursor: pointer;
  &:hover {
    background: #4799af;
  }
  &.disabled {
    cursor: wait;
    background: #8dc2cf;
  }
}

select.form-control {
  height: 60px;
  padding: 0 17px;
  border-radius: 5px;
}

.input-container {
  position: relative;
  /* margin-bottom: 1rem; */
}

.top-placeholder {
  position: absolute;
  pointer-events: none;
  left: 1.5rem;
  top: -0.2rem;
  font-size: 0.9rem;
  color: #333;
  background-color: #f8f9fb;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 700;
}

.error-div {
  color: red;
  opacity: 75%;
  margin: 1.5rem 0;
}
.empty-form {
  border-color: red !important;
}
